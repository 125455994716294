/* Основные цвета*/

/* Шрифты*/

:root {
    --main-font: 'Open Sans', sans-serif;
}

html {
    overflow: hidden;
}

body {
    font-family: var(--main-font) !important;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: var(--default);
}

@media(max-width:480px) {
    body {
        font-size: 14px !important;
    }
}