.modal-window {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.8);
}
.modal-window.modal-window_pdf {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0px);
}
.modal-window.modal-window_pdf .modal-window__header {
  color: #fff;
  background-color: #000;
  height: 50px;
  padding-bottom: 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.modal-window.modal-window_pdf .modal-window__container {
  margin: 0;
  padding: 0;
  height: 100vh;
}
.modal-window.modal-window_pdf .modal-window__scroll {
  align-items: flex-start;
}
.modal-window.modal-window_pdf .modal-window__header-title {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 16px;
  overflow: hidden;
}
.modal-window.modal-window_pdf .modal-window__header-title span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-window.modal-window_pdf .modal-window__close {
  position: inherit;
  top: inherit;
  right: inherit;
  z-index: inherit;
  display: flex;
  align-items: center;
  height: 100%;
}
.modal-window.modal-window_pdf .modal-window__close div {
  padding: 0px 25px;
  background-size: 40% auto;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 100%;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIxMDAwIiB3aWR0aD0iMTAwMCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8bWV0YWRhdGE+SWNvRm9udCBJY29uczwvbWV0YWRhdGE+Cjx0aXRsZT5jbG9zZS1saW5lPC90aXRsZT4KPGdseXBoIGdseXBoLW5hbWU9ImNsb3NlLWxpbmUiIHVuaWNvZGU9IiYjeGVlZTE7IiBob3Jpei1hZHYteD0iMTAwMCIgLz4KPHBhdGggIGZpbGw9IiNmZmZmZmYiIGQ9Ik03NjIuNSAyMDBsMzcuNSAzNy41LTI2Mi41IDI2Mi41IDI2Mi41IDI2Mi41LTM3LjUgMzcuNS0yNjIuNS0yNjIuNS0yNjIuNSAyNjIuNS0zNy41LTM3LjUgMjYyLjUtMjYyLjUtMjYyLjUtMjYyLjUgMzcuNS0zNy41IDI2Mi41IDI2Mi41eiIvPgo8L3N2Zz4=);
}
.modal-window.modal-window_pdf .modal-window__close div:hover {
  background-color: #777;
}
@media (max-width: 768px) {
  .modal-window {
    background-color: rgba(255, 255, 255, 0.9);
  }
}
.modal-window__scroll {
  overflow: auto;
  height: 100%;
  z-index: 9;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-window__container {
  position: relative;
  margin: auto;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 96px);
}
@media (max-width: 580px) {
  .modal-window__container {
    padding: 80px 16px 24px 16px;
  }
}
.modal-window__container .button-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal-window__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-window__subtitle {
  font-size: 24px;
  padding-bottom: 24px;
}
@media (max-width: 768px) {
  .modal-window__subtitle {
    font-size: 18px;
    padding-bottom: 12px;
  }
}
.modal-window__title {
  font-size: 48px;
  padding-bottom: 32px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .modal-window__title {
    font-size: 36px;
    padding-bottom: 16px;
  }
}
@media (max-width: 480px) {
  .modal-window__title {
    font-size: 32px;
  }
}
.modal-window__time {
  font-size: 48px;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .modal-window__time {
    font-size: 36px;
  }
}
@media (max-width: 480px) {
  .modal-window__time {
    font-size: 24px;
  }
}
.modal-window__time-icon {
  padding-right: 24px;
  filter: invert(var(--icons_default_inverse));
}
@media (max-width: 480px) {
  .modal-window__time-icon {
    padding-right: 12px;
  }
}
@media (max-width: 560px) {
  .modal-window__time-text {
    font-size: 24px;
  }
}
@media (max-width: 400px) {
  .modal-window__time-text {
    font-size: 18px;
  }
}
.modal-window__main {
  flex: 1;
  width: 100%;
}
.modal-window__main.cansel {
  align-items: center;
  justify-content: center;
}
.modal-window__input-block {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
}
.modal-window__input-block form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-window__textarea {
  width: 100%;
  height: 200px;
  margin-bottom: 24px;
  margin-top: 28px;
}
.modal-window__textarea textarea {
  height: 100%;
}
.modal-window__button {
  max-width: 400px;
  width: 100%;
}
.modal-window__button button {
  width: 100%;
}
.modal-window__main-block {
  width: calc(100% / 3 - 32px);
  margin: 16px;
}
@media (max-width: 1100px) {
  .modal-window__main-block {
    width: calc(100% / 2 - 32px);
  }
}
@media (max-width: 768px) {
  .modal-window__main-block {
    width: 100%;
  }
}
.modal-window__header {
  text-align: center;
  padding-bottom: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 560px) {
  .modal-window__header {
    padding-bottom: 16px;
  }
}
.modal-window__close {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 99;
}
@media (max-width: 560px) {
  .modal-window__close {
    top: 20px;
    right: 20px;
  }
}
.modal-window__serives {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.modal-window__serives-block {
  padding: 16px;
  width: calc(100% / 3 - 32px);
}
@media (max-width: 991px) {
  .modal-window__serives-block {
    width: calc(100% / 2 - 32px);
  }
}
@media (max-width: 768px) {
  .modal-window__serives-block {
    width: 100%;
  }
}
.all-documents .modal-window__content {
  height: 100%;
}
