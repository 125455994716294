.icon {
  background-repeat: no-repeat;
}
.icon_background-contain {
  background-size: contain;
}
.icon_background-auto {
  background-size: auto 100%;
}
.icon_xs {
  width: 24px;
  height: 24px;
}
.icon_s {
  width: 32px;
  height: 32px;
}
.icon_m {
  width: 40px;
  height: 40px;
}
.icon_l {
  width: 48px;
  height: 48px;
}
.icon_xl {
  width: 64px;
  height: 64px;
}
.icon_xxl {
  width: 72px;
  height: 72px;
}
.icon_big {
  width: 80px;
  height: 80px;
}
span.icon {
  display: block;
}
.icon_right {
  background-position-x: right;
}
.icon_left {
  background-position-x: left;
}
.icon_button-branch {
  background-image: url('../../img/icons/button-location.png');
}
.icon_button-record {
  background-image: url('../../img/icons/reception.png');
}
.icon_button-viewing {
  background-image: url('../../img/icons/button-viewing.png');
}
.icon_button-close {
  background-image: url('../../img/icons/button-close.png');
}
.icon_button-input {
  background-image: url('../../img/icons/button-input.png');
}
.icon_input-phone {
  background-image: url('../../img/icons/phone.png');
}
.icon_input-password {
  background-image: url('../../img/icons/password.png');
}
.icon_services {
  background-image: url('../../img/icons/services-icon.png');
}
.icon_documents {
  background-image: url('../../img/icons/all-documents-icon.png');
}
.icon_notes {
  background-image: url('../../img/icons/my-notes-icon.png');
}
.icon_reception {
  background-image: url('../../img/icons/reception-icon.png');
}
.icon_profile-block {
  background-image: url('../../img/icons/profile-icon.png');
}
.icon_card-info {
  background-image: url('../../img/icons/crad-info.png');
}
.icon_close-reception {
  background-image: url('../../img/icons/close-reseption.png');
}
.icon_recepiton-complete {
  background-image: url('../../img/icons/reception-complete.png');
}
.icon_recepiton-time {
  background-image: url('../../img/icons/icon-time.png');
}
.icon_document-dolwnd {
  background-image: url('../../img/icons/document-dolwnd.png');
}
.icon_calendar {
  background-image: url('../../img/icons/calendar.png');
}
.icon_filter {
  background-image: url('../../img/icons/filter.png');
}
.icon_filter-white {
  background-image: url('../../img/icons/filter-white.png');
}
.icon_close-filter {
  background-image: url('../../img/icons/close-filter.png');
}
.icon_discount {
  filter: invert(var(--icons_primary_inverse));
  background-image: url('../../img/icons/discount-icon.png');
}
.icon_change-password {
  background-image: url('../../img/icons/change-password.png');
}
.icon_type-reception {
  background-image: url('../../img/icons/type-reception.png');
}
.icon_step-1 {
  background-image: url('../../img/icons/recep-1.png');
}
.icon_step-2 {
  background-image: url('../../img/icons/recep-2.png');
}
.icon_step-3 {
  background-image: url('../../img/icons/recep-3.png');
}
.icon_step-4 {
  background-image: url('../../img/icons/recep-4.png');
}
.icon_step-5 {
  background-image: url('../../img/icons/recep-5.png');
}
.icon_reception-done {
  background-image: url('../../img/icons/reception-done.png');
}
.icon_reception-prev {
  background-image: url('../../img/icons/reception-prev.png');
}
.icon_reception-next {
  background-image: url('../../img/icons/reception-next.png');
}
.icon_services-accardion {
  background-image: url('../../img/icons/accardion.png');
}
.icon_next {
  background-image: url('../../img/icons/next.png');
}
.icon_prev {
  background-image: url('../../img/icons/prev.png');
}
.icon_messageSwipe {
  background-image: url('../../img/icons/swipe.png');
}
.icon_message-swipe-close {
  background-image: url('../../img/icons/close1.png');
}
.icon_swipeDown {
  background-image: url('../../img/icons/swipe-down.png');
}
.icon_search {
  background-image: url('../../img/icons/search.png');
}
