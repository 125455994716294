.rc-pagination {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  font-weight: bold;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index: 999;
}
@media (max-width: 768px) {
  .rc-pagination {
    justify-content: center;
    position: fixed;
    bottom: 48px;
    width: 100%;
    left: 0;
    background-color: #FFF;
    padding: 8px 16px;
    box-sizing: border-box;
    box-shadow: 0px -15px 25px 0px rgba(0, 0, 0, 0.16);
  }
}
.rc-pagination-item {
  border-radius: 8px;
  padding: 10px 8px;
  min-width: 21px;
  text-align: center;
  cursor: pointer;
  margin: 8px 4px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .rc-pagination-item {
    margin: 8px 0px;
  }
}
.rc-pagination-item:hover {
  box-shadow: 0px 0px 0px 2px var(--primary);
  color: var(--primary);
}
.rc-pagination-item-active {
  background-color: var(--primary);
  box-shadow: 0px 0px 0px 2px var(--default);
  color: var(--default);
}
.rc-pagination-item-active:hover {
  color: var(--default);
}
.rc-pagination-prev button,
.rc-pagination-next button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 2px solid var(--primary);
  background-color: transparent;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 12px;
}
.rc-pagination-prev button:after,
.rc-pagination-next button:after {
  content: '';
  display: block;
  background-size: auto 100%;
  background-position: center;
  width: 16px;
  height: 16px;
}
.rc-pagination-prev button:after {
  background-image: url('../../img/icons/left-arrow.png');
}
.rc-pagination-next button:after {
  background-image: url('../../img/icons/right-arrow.png');
}
.rc-pagination .rc-pagination-next.rc-pagination-disabled,
.rc-pagination .rc-pagination-prev.rc-pagination-disabled {
  opacity: 0.3;
}
@media (max-width: 480px) {
  .rc-pagination .rc-pagination-prev {
    order: 9;
    width: calc(50% - 4px);
    margin-left: 4px;
    display: none;
  }
}
@media (max-width: 480px) {
  .rc-pagination .rc-pagination-prev button {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 480px) {
  .rc-pagination .rc-pagination-next {
    order: 10;
    width: calc(50% - 4px);
    margin-left: 4px;
    display: none;
  }
}
@media (max-width: 480px) {
  .rc-pagination .rc-pagination-next button {
    width: 100%;
    margin: 0;
  }
}
.rc-pagination .rc-pagination-jump-next button,
.rc-pagination .rc-pagination-jump-prev button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.rc-pagination .rc-pagination-jump-next button:after,
.rc-pagination .rc-pagination-jump-prev button:after {
  content: '';
  display: block;
  background-size: auto 100%;
  background-position: center;
  width: 24px;
  height: 24px;
  background-image: url('../../img/icons/menu.png');
}
