.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.308);
    backdrop-filter: blur(10px);
    animation-name: appear;
    animation-duration: 300ms;
  }

  .modal-container {
    width: 100%;
    height: 500px;
    max-width: 900px;
    position: relative;
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    overflow: auto;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
  }

  .modal-image{
    object-fit: scale-down;
    border-radius: 12px;
    display: block;
    height: 100%;
  }
  
  .modal-dialog {
    width: 100%;
    background: white;
    border-radius: 12px;
    margin: 0 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: large;
  }

  .modal-close-btn {
    position: relative;
  }

  .modal-icon-swipe {
    display: none;
  }

  @media (max-width: 768px) {
    .modal-title {
      font-weight: bold;
      font-size: medium;
    }

    .modal-container {
      flex-direction: column;
      width: 400px;
      height: 100%;
      margin-top: 50px;
      max-width: 100%;
      border-radius: 12px;
      align-items: center;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .modal-image{
      display: block;
      width: 70%;
      max-width: 400px;
      height: auto;
      margin-bottom: 12px;
      box-shadow: none;
    }

    .modal-dialog {
      margin: 0;
      margin-bottom: 50px;
      border-radius: 12px;
    }

    .modal-bottom {
      position: fixed;
      height: 0px;
      width: 100%;
      bottom: 0px;
      box-shadow: 0px -3px 25px 20px rgba(0, 0, 0, 0.81);
    }

    .modal-icon-swipe {
      display: block;
      position: fixed;
      height: 0px;
      bottom: 100px;
      opacity: 1;
      box-shadow: 0px 20px 13px 20px rgb(255 255 255);
      animation-name: up-down;
      animation-duration: 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
  
  .modal-header,
  .modal-footer {
    display: flex;
    align-items: center;
    padding: 1rem;
  }
  
  .modal-header {
    border-bottom: 1px solid #dbdbdb;
    justify-content: space-between;
  }
  
  .modal-body {
    overflow: auto;
    margin-bottom: 15px;
  }
  
  .modal-content {
    padding: 1rem;
    margin-bottom: 10px;
  }

  p {
    line-height: 2em;
  }

  b {
    font-weight: bold;
  }
  
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slide-in {
    from {
      transform: translateY(-150px);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes up-down {
    0% {
      bottom: 100px;
      opacity: 1;
    }
    25% {
      bottom: 120px;
    }
    50% {
      bottom: 100px;
    }
    75% {
      bottom: 120px;
      opacity: 1;
    }
    100% {
      bottom: 100px;
      opacity: 0;
    }
  }