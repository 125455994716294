.card {
  position: relative;
  min-height: 100%;
  display: flex;
  color: var(--text);
}
.card__notes-img {
  filter: invert(var(--icons_default_inverse));
}
.card_notes-success .card__notes-img,
.card_notes-warning .card__notes-img,
.card_notes-danger .card__notes-img {
  filter: none;
}
.card__container {
  display: flex;
  flex-direction: column;
  background-color: var(--default);
  border-radius: 12px;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
  padding: 16px;
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
}
@media (max-width: 480px) {
  .card__container {
    padding: 16px 24px;
  }
}
.card.disabled:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  opacity: 0.5;
  border-radius: 12px;
}
.card.disabled .card__background {
  opacity: 0.5;
}
.card__background {
  position: absolute;
  display: flex;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 16px);
  height: 100%;
  z-index: -1;
  border-radius: 12px;
}
.card_background-default {
  background-color: var(--default);
  filter: brightness(0.9);
}
.card_background-warning {
  background-color: var(--warning);
}
.card_background-danger {
  background-color: var(--danger);
}
.card_background-success {
  background-color: var(--success);
}
.card_background-primary {
  background-color: var(--primary);
}
.card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}
@media (max-width: 480px) {
  .card__header {
    padding-bottom: 8px;
  }
}
.card__content {
  padding-bottom: 16px;
  flex: 1;
}
@media (max-width: 480px) {
  .card__content {
    padding-bottom: 16px;
  }
}
.card__subtitle {
  font-size: 14px;
}
.card__title {
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (min-width: 768px) {
  .card__title {
    min-height: 32px;
  }
}
@media (max-width: 480px) {
  .card__title {
    font-size: 16px;
  }
}
.card__info-icon {
  cursor: pointer;
  filter: invert(var(--icons_default_inverse));
}
.card__info {
  padding-top: 8px;
  display: none;
}
.card__additional-info {
  display: none;
  padding-top: 24px;
}
.card.active .card__additional-info {
  display: block;
}
.card__additional-info p {
  padding: 4px 0;
}
.card__additional-info p:first-child {
  padding-top: 0;
}
.card__additional-info p:last-child {
  padding-bottom: 0;
}
.card__additional-content {
  display: none;
  padding-bottom: 16px;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 480px) {
  .card__additional-content {
    font-size: 14px;
  }
}
.card__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card__line {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  color: var(--gray);
  gap: 10px;
}
@media (max-width: 480px) {
  .card__line {
    padding-bottom: 16px;
  }
}
.card__border-line {
  flex: 1;
  height: 1px;
  background-color: var(--gray);
  opacity: 0.2;
}
.card__line-text {
  display: contents;
  font-size: 14px;
}
.card__line-text strong {
  font-weight: bold;
}
.card_services .card__info {
  display: none;
}
.card_services .card_services-price {
  font-size: 18px;
  font-weight: bold;
}
@media (max-width: 480px) {
  .card_services .card_services-price {
    font-size: 18px;
  }
}
.card_services .card__additional-content {
  display: none;
  padding-bottom: 16px;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 480px) {
  .card_services .card__additional-content {
    font-size: 14px;
  }
}
.card_services .card__services-status {
  display: flex;
  padding: 8px;
  border-radius: 6px;
  align-items: center;
}
.card_services .card__services-status.card_services-danger {
  background-color: var(--danger);
  color: var(--default);
}
.card_services .card__services-status.card_services-warning {
  background-color: var(--warning);
  color: var(--default);
}
.card_services .card__services-img {
  width: 16px;
  height: 16px;
  margin: 2px;
}
.card_services .card__services-text {
  padding-left: 8px;
}
@media (max-width: 480px) {
  .card_services .card__services-text {
    font-size: 14px;
  }
}
.card_services .card_services-success .card__services-img {
  background-color: var(--success);
  border-radius: 100%;
}
.card_services .card_services-danger .card__services-img {
  border-radius: 100%;
  box-shadow: inset 0 0 0 2px var(--default);
}
.card_services .card_services-warning .card__services-img {
  background-image: url('../../img/icons/serives-status-warning.png');
}
.card_notes.cancel {
  opacity: 0.5;
}
.card_notes .card__notes-status,
.card_notes .card__notes-time div {
  display: flex;
  align-items: center;
}
.card_notes .card__notes-status {
  cursor: pointer;
}
.card_notes .card__notes-text {
  padding-left: 8px;
}
@media (max-width: 480px) {
  .card_notes .card__notes-text {
    font-size: 14px;
  }
}
.card_notes .card__info {
  display: block;
}
@media (max-width: 480px) {
  .card_notes .card__info {
    font-size: 14px;
  }
}
.card_notes .card__additional-content {
  display: block;
}
.card_notes .card__notes-time {
  padding: 8px;
  border-radius: 6px;
  color: var(--default);
  font-size: 14px;
  display: flex;
}
.card_notes .card__notes-time.card_notes-success {
  background-color: var(--success);
}
.card_notes .card__notes-time.card_notes-danger {
  background-color: var(--danger);
}
.card_notes .card__notes-time.card_notes-warning {
  background-color: var(--warning);
}
.card_notes .card__notes-time.card_notes-complete {
  color: var(--text);
}
.card_notes .card__notes-time.card_notes-cancel {
  color: var(--text);
}
.card_documents .card__info {
  display: none;
}
.card_documents .card__additional-content {
  display: none;
}
.card_documents .card__documents-dolwnd {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.card_documents .card__documents-img {
  filter: invert(var(--icons_default_inverse));
}
.card_documents .card__documents-text {
  padding-left: 8px;
}
@media (max-width: 480px) {
  .card_documents .card__documents-text {
    font-size: 14px;
  }
}
.card_documents .card__documents-text:hover {
  text-decoration: underline;
}
