.profile {
  position: fixed;
  top: 0;
  right: -100%;
  width: 600px;
  height: 100%;
  z-index: 9999;
  background-color: var(--default);
  color: var(--text) !important;
  transition: all 0.5s ease;
}
@media (max-width: 768px) {
  .profile {
    width: 100%;
  }
}
.profile__container {
  padding: 48px 32px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}
@media (max-width: 768px) {
  .profile__container {
    padding: 32px 24px;
  }
}
.profile__header {
  display: flex;
  align-items: center;
  padding-bottom: 48px;
}
@media (max-width: 768px) {
  .profile__header {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .profile__header-text {
    padding-top: 32px;
  }
}
.profile__header-welcome {
  padding-bottom: 8px;
}
.profile__header-name {
  font-size: 24px;
  font-weight: bold;
}
.profile__content {
  padding-bottom: 32px;
}
.profile__content-item {
  margin-bottom: 24px;
}
.profile__content-item strong {
  font-weight: bold;
}
.profile__footer {
  border-top: 1px solid var(--gray);
  padding-top: 24px;
}
.profile__button-close {
  padding-right: 24px;
}
.profile__button-close .button-close {
  width: 60px;
  height: 60px;
}
@media (max-width: 768px) {
  .profile__button-close {
    display: none;
  }
}
.profile__mobile-header {
  display: none;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid;
}
@media (max-width: 768px) {
  .profile__mobile-header {
    display: flex;
  }
}
.profile__mobile-title {
  font-size: 28px;
  font-weight: bold;
}
.profile.active {
  right: 0;
  box-shadow: -25px 0px 50px 0px rgba(0, 0, 0, 0.16);
}
.profile__change-password {
  padding-bottom: 16px;
}
.profile__discount {
  padding-bottom: 48px;
}
.profile-plug {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
}
.profile-plug.active {
  display: block;
}
.discount-block {
  width: 500px;
  position: relative;
  height: 100%;
}
@media (max-width: 768px) {
  .discount-block {
    width: 100%;
  }
}
.discount-block__header {
  background-color: var(--primary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--default);
  padding: 24px;
  box-shadow: 0px 34px 0px 0px rgba(200, 200, 200, 0.5);
}
@media (max-width: 768px) {
  .discount-block__header {
    padding: 32px 28px;
  }
}
.discount-block__footer {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
}
@media (max-width: 400px) {
  .discount-block__footer {
    font-size: 11px;
  }
}
.discount-block__discount {
  font-size: 48px;
  font-weight: bold;
}
@media (max-width: 440px) {
  .discount-block__discount {
    font-size: 28px;
  }
}
.discount-block__text {
  text-transform: uppercase;
  font-weight: bold;
  max-width: 195px;
  line-height: 1.4;
}
@media (max-width: 440px) {
  .discount-block__text {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .discount-block__icon {
    padding-right: 8px;
  }
}
@media (max-width: 480px) {
  .discount-block__icon {
    display: none;
  }
}
.modal-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-password form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-password__form-button {
  width: 100%;
}
.modal-password__form-button button {
  width: 100%;
}
.modal-password__old-pass {
  padding-bottom: 32px;
}
.modal-password__new-pass {
  padding-bottom: 32px;
}
