.all-documents {
  transition: all 0.5s ease;
}
@media (min-width: 1140px) {
  .all-documents {
    padding-bottom: 0px !important;
  }
}
.all-documents__container {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media (max-width: 1600px) {
  .all-documents__container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 1300px) {
  .all-documents__container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .all-documents__container {
    grid-template-columns: 1fr;
    padding-top: 32px;
  }
}
