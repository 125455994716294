.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0px;
}
@media (max-width: 1140px) {
  .navigation {
    flex-direction: row;
    padding: 24px 0px;
    justify-content: space-around;
  }
}
@media (max-width: 768px) {
  .navigation {
    padding: 16px 0px;
  }
}
@media (max-width: 480px) {
  .navigation {
    padding: 14px 0px;
  }
}
.navigation__item {
  margin-bottom: 40px;
}
@media (max-width: 1140px) {
  .navigation__item {
    margin-bottom: 0px;
  }
}
.navigation__item:last-child {
  margin-bottom: 0;
}
.navigation__icon {
  margin-bottom: 12px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  filter: invert(var(--icons_primary_inverse));
}
@media (max-width: 1140px) {
  .navigation__icon {
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
  }
}
@media (max-height: 760px) {
  .navigation__icon {
    width: 32px;
    height: 32px;
  }
}
.navigation__link {
  position: relative;
}
@media (max-width: 440px) {
  .navigation__link {
    font-size: 12px;
  }
}
.navigation a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text_inverse);
  text-decoration: none;
  font-size: 14px;
  opacity: 0.5;
}
@media (max-width: 768px) {
  .navigation a {
    font-size: 12px;
  }
}
.navigation a:hover {
  opacity: 1;
}
.navigation a:hover .navigation__icon {
  opacity: 1;
}
.navigation a:focus {
  outline: none;
}
.navigation a.active {
  opacity: 1;
}
.navigation a.active .navigation__link:before {
  content: '';
  position: absolute;
  background-color: var(--text_inverse);
  width: 100%;
  height: 2px;
  bottom: -9px;
  border-radius: 5px;
}
@media (max-width: 1140px) {
  .navigation a.active .navigation__link:before {
    width: 100%;
    height: 5px;
    bottom: -24px;
    border-radius: 5px 5px 0px 0px;
  }
}
@media (max-width: 768px) {
  .navigation a.active .navigation__link:before {
    bottom: -16px;
  }
}
@media (max-width: 480px) {
  .navigation a.active .navigation__link:before {
    bottom: -14px;
  }
}
.navigation a.active .navigation__icon {
  opacity: 1;
}
