input,
textarea {
  border: none;
  background-color: var(--default);
  border-radius: 12px;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
}
input:focus,
textarea:focus {
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 4px var(--primary);
  -webkit-box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 4px var(--primary);
}
input[type=text],
input[type=tel],
input[type=password],
textarea {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: var(--default);
  border-radius: 12px;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  padding: 24px 24px 24px 70px;
  width: calc(100% - 94px);
}
input[type=text]:focus,
input[type=tel]:focus,
input[type=password]:focus,
textarea:focus {
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 4px var(--primary);
  -webkit-box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 4px var(--primary);
}
textarea {
  padding: 24px;
  width: 100%;
  font-family: var(--main-font);
  box-sizing: border-box;
}
input {
  padding: 24px 24px 24px 70px;
  width: calc(100% - 94px);
}
.input {
  position: relative;
  display: flex;
  align-items: center;
}
.input textarea {
  display: none;
}
.input__icon {
  content: '';
  position: absolute;
  display: flex;
  width: 32px;
  height: 32px;
  background-size: 100%;
  z-index: 999;
  left: 20px;
}
.input__icon__right {
  content: '';
  position: absolute;
  display: flex;
  width: 32px;
  height: 32px;
  z-index: 999;
  right: 20px;
}

.input__icon__right:hover > span {
  box-sizing: border-box;
  border: 4px solid transparent;
}

.input.error input {
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 4px var(--danger);
}
.input.error input:focus {
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 4px var(--primary);
}
.textarea.error textarea {
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 4px var(--danger);
}
.textarea.error textarea:focus {
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 4px var(--primary);
}
.textarea input {
  display: none;
}
.textarea .icon {
  display: none;
}
.error-message {
  margin-bottom: 17px;
  font-size: 14px;
  background-color: var(--danger);
  color: var(--default);
  padding: 12px;
  border-radius: 4px;
  max-width: 300px;
  text-align: center;
  box-sizing: border-box;
}
