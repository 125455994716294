.menu {
  position: relative;
}
.menu__button {
  padding: 8px;
  border-radius: 12px 12px 0 0;
  margin: -8px;
}
.menu.active .menu__button {
  background: var(--primary);
}
.menu.active .menu__list {
  display: block;
  width: 240px;
  padding: 16px;
  right: -8px;
  top: calc(100% + 8px);
  position: absolute;
  background: var(--primary);
  border-radius: 12px 0 12px 12px;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
}
.menu .button-link {
  color: var(--text_inverse);
}
.menu .button-link__text {
  margin-left: 0;
}
.menu__button .icon {
  background-size: auto 100%;
  filter: invert(var(--icons_default_inverse));
}
.menu__button .icon:hover {
  filter: invert(var(--icons_primary_inverse));
}
.menu.active .menu__button .icon {
  background-position: right;
}
@media (max-width: 768px) {
  .menu__button .icon {
    width: 48px;
    height: 48px;
  }
  .menu__button {
    padding: 8px;
    border-radius: 12px 12px 0 0;
    margin: -8px -8px -8px 0;
  }
  .menu.active .menu__list {
    right: -8px;
    top: calc(100%);
  }
}
