.my-notes {
  transition: all 0.5s ease;
}
@media (min-width: 1140px) {
  .my-notes {
    padding-bottom: 0px !important;
  }
}
.my-notes__container {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media (max-width: 1600px) {
  .my-notes__container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 1300px) {
  .my-notes__container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .my-notes__container {
    grid-template-columns: 1fr;
    padding-top: 32px;
  }
}
