.DateRangePicker {
  font-family: var(--main-font) !important;
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: calc(100% + 16px);
  background-color: var(--default);
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  width: 100%;
  left: 0;
  min-width: 275px;
  padding: 32px 24px;
}
@media (max-width: 768px) {
  .DateRangePicker {
    border-radius: 4px;
    top: 0;
  }
}
@media (max-width: 400px) {
  .DateRangePicker {
    min-width: auto;
    padding: 32px 12px;
  }
}
.DateRangePicker__Legend {
  color: #52575e;
  font-size: 14px;
  line-height: 16px;
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
}
.DateRangePicker__LegendItem {
  display: inline-block;
  margin: 0 20px;
}
.DateRangePicker__LegendItemColor {
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  margin-right: 6px;
  vertical-align: text-bottom;
  width: 14px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.DateRangePicker__LegendItemColor--selection {
  background-color: var(--primary);
}
.DateRangePicker__PaginationArrow {
  border: 0;
  cursor: pointer;
  display: block;
  height: 35px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 30px;
  white-space: nowrap;
  width: 35px;
  z-index: 1;
}
.DateRangePicker__PaginationArrow:hover {
  box-shadow: 0px 0px 0px 1px var(--primary);
  border-radius: 8px;
}
.DateRangePicker__PaginationArrow--previous {
  left: 20px;
}
.DateRangePicker__PaginationArrow--next {
  right: 20px;
}
.DateRangePicker__PaginationArrowIcon {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  height: 0;
  position: absolute;
  top: 10px;
  width: 0;
}
.DateRangePicker__PaginationArrowIcon--is-disabled {
  opacity: 0.25;
}
.DateRangePicker__PaginationArrowIcon--previous {
  border-left: 8px solid transparent;
  border-right: 8px solid var(--primary);
  left: 4px !important;
}
.DateRangePicker__PaginationArrowIcon--next {
  border-left: 8px solid var(--primary);
  border-right: 8px solid transparent;
  left: 15px;
}
.DateRangePicker__Month {
  color: var(--text);
  display: inline-block;
  margin: 0 0px;
  position: relative;
  user-select: none;
  width: 100%;
}
.DateRangePicker__MonthHeader {
  color: var(--text);
  font-size: 14px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  position: relative;
  text-align: center;
  padding-bottom: 5px;
}
.DateRangePicker__MonthHeaderLabel {
  display: inline-block;
  position: relative;
}
.DateRangePicker__MonthHeaderSelect {
  background: #e4e4e4;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.DateRangePicker__MonthDates {
  border-bottom: none;
  border-collapse: separate;
  border-spacing: 0 1px;
  margin: 0;
  width: 100%;
}
.DateRangePicker__WeekdayHeading,
.DateRangePicker__Date {
  font-size: 12px;
  line-height: 1;
  padding: 12px 0;
  text-align: center;
  width: 14.28571429%;
  font-size: 14px;
}
.DateRangePicker__WeekdayHeading {
  border-bottom: none;
  color: var(--text);
  font-weight: bold;
}
.DateRangePicker__WeekdayHeading abbr[title] {
  border-bottom-width: 0;
  color: var(--text);
  cursor: pointer;
  font-size: inherit;
  text-decoration: none;
}
.DateRangePicker__Date {
  border: none;
  border-right-width: 1px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.DateRangePicker__Date:first-child {
  border-left-width: 1px;
}
.DateRangePicker__Date--weekend {
  background-color: #f6f7f9;
}
.DateRangePicker__Date--otherMonth {
  opacity: 0.25;
}
.DateRangePicker__Date--is-disabled {
  color: #cdcdd1;
  cursor: default;
}
.DateRangePicker__Date--is-selected {
  color: #fff;
}
.DateRangePicker__Date--is-highlighted {
  color: #333;
}
.DateRangePicker__CalendarDatePeriod {
  bottom: 0;
  position: absolute;
  top: 0;
}
.DateRangePicker__CalendarDatePeriod--am {
  left: 0;
  right: 50%;
}
.DateRangePicker__CalendarDatePeriod--pm {
  left: 50%;
  right: 0;
}
.DateRangePicker__CalendarSelection {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  bottom: 4px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 4px;
  border-radius: 8px;
  margin: 0px 2px;
}
f .DateRangePicker__CalendarSelection--inOtherMonth {
  opacity: 0.5;
}
.DateRangePicker__CalendarSelection--segment {
  border-left-width: 0;
  border-right-width: 0;
}
.DateRangePicker__CalendarSelection--single {
  border-radius: 5px;
  left: 5px;
  right: 5px;
}
.DateRangePicker__CalendarSelection--is-pending {
  background-color: var(--primary);
  border-width: 0;
  color: #fff;
  opacity: 0.75;
}
.DateRangePicker__CalendarHighlight {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px;
}
.DateRangePicker__CalendarHighlight--inOtherMonth {
  opacity: 0.5;
}
.DateRangePicker__CalendarHighlight--start {
  border-bottom-left-radius: 5px;
  border-right-width: 0;
  border-top-left-radius: 5px;
  left: 5px;
}
.DateRangePicker__CalendarHighlight--end {
  border-bottom-right-radius: 5px;
  border-left-width: 0;
  border-top-right-radius: 5px;
  right: 5px;
}
.DateRangePicker__CalendarHighlight--segment {
  border-left-width: 0;
  border-right-width: 0;
}
.DateRangePicker__CalendarHighlight--single {
  background-color: var(--default);
  box-shadow: 0px 0px 0px 1px var(--primary);
  bottom: 4px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 4px;
  border-radius: 8px;
  margin: 0px 2px;
  border: none;
}
.DateRangePicker__HalfDateStates {
  bottom: -50px;
  left: -50px;
  position: absolute;
  right: -50px;
  top: -50px;
  transform: rotate(30deg);
}
.DateRangePicker__FullDateStates {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.DateRangePicker__DateLabel {
  display: block;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
  font-size: 16px;
}
.filter-data {
  color: var(--text_inverse);
  padding: 8px 24px;
  display: flex;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 10;
}
@media (max-width: 768px) {
  .filter-data {
    border-left: none;
    padding: 0px;
  }
}
.filter-data__container {
  width: 100%;
  height: 100%;
}
.filter-data__content {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.filter-data__triger {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .filter-data__title {
    padding-bottom: 16px;
  }
}
.filter-data__title.bold {
  font-weight: bold;
}
.filter-data__content div div:not(.filter-data__title) {
  opacity: 0.5;
}
.filter-data__content.active div div:not(.filter-data__title) {
  opacity: 1;
}
.filter-data__button-close {
  display: none;
}
.filter-data__button-close.active {
  display: block;
}
