.sidebar {
  height: 100vh;
  width: 200px;
  background-color: var(--primary);
  box-shadow: 25px 0px 50px 0px rgba(0, 0, 0, 0.16);
  overflow: auto;
  position: fixed;
  z-index: 99;
}
@media (max-width: 1140px) {
  .sidebar {
    z-index: 9999;
    height: auto;
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: 0px -15px 25px 0px rgba(0, 0, 0, 0.16);
  }
}
.sidebar__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
@media (max-width: 480px) {
  .sidebar__container {
    padding: 0;
  }
}
.sidebar__header {
  width: 128px;
  padding-top: 40px;
}
.sidebar__header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1140px) {
  .sidebar__header {
    display: none;
  }
}
@media (max-height: 760px) {
  .sidebar__header {
    width: 96px;
  }
}
@media (max-width: 1140px) {
  .sidebar__content {
    width: 100%;
  }
}
.sidebar__footer {
  text-align: center;
  color: var(--text_inverse);
  padding-bottom: 40px;
  font-size: 12px;
}
@media (max-width: 1140px) {
  .sidebar__footer {
    display: none;
  }
}
