.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(../../img/freepik--Floor--inject-5.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  transition: all 0.5s ease;
}
@media (max-width: 768px) {
  .login {
    background-size: 150% auto;
  }
}
@media (max-height: 600px) {
  .login {
    height: auto;
  }
}
.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 512px;
}
@media (max-width: 580px) {
  .login__container {
    padding: 0px 16px;
    min-width: auto;
  }
}
@media (max-height: 600px) {
  .login__container {
    padding: 32px 16px;
  }
}
.login__logo {
  max-width: 180px;
  padding-bottom: 24px;
}
.login__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.login__subtitle {
  max-width: 540px;
  text-align: center;
  padding-bottom: 50px;
}
.login__form-button {
  padding-bottom: 50px;
}
.login__form-button button {
  width: 100%;
}
.login__form {
  max-width: 440px;
  width: 100%;
}
.login__form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login__form .error-message {
  max-width: 100%;
}
.login__form-button,
.login__form-phone,
.login__form-password {
  width: 100%;
}
.login__form-phone,
.login__form-password {
  padding-bottom: 16px;
}
.login__title,
.login__number {
  font-size: 48px;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 24px;
}
.login__number {
  padding-bottom: 60px;
}
.login_reset-password a {
  color: var(--text);
}
.login_reset-password .login__subtitle {
  padding-bottom: 32px;
}
@media (max-width: 768px) {
  .login_reset-password .login__title,
  .login_reset-password .login__number {
    font-size: 36px;
  }
  .login_reset-password .login__container {
    text-align: center;
  }
}
