.search {
  margin-bottom: 32px;
}
.search input {
  padding: 16px !important;
  width: calc(100% - 32px);
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.16);
  -webkit-appearance: none;
  appearance: none;
}
@media (max-width: 768px) {
  .search input {
    width: calc(100% - 32px);
    padding: 16px !important;
  }
}
.search__container {
  position: relative;
}
.search__icon {
  position: absolute;
  background-color: var(--primary);
  width: 70px;
  height: 100%;
  right: 0;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .search__icon {
    width: 48px;
  }
}
